<template>
  <div class="slider">
    <div
      class="slider-container"
      @scroll="scrollSlider"
      v-if="indexLoading !== 'empty'"
    >
      <a v-for="(item, idx) in indexBannerComp" :href="item.link" :key="idx"
        ><picture>
          <img :src="item.image.replace('{sizeId}', getSizeImage)" alt="" />
        </picture>
      </a>
    </div>
    <div class="dots" v-if="indexBannerComp.length > 1">
      <a
        href="#"
        v-for="(item, idx) in indexBannerComp"
        :key="item.id"
        @click.prevent="setStep(idx)"
        :class="{ current: step === idx }"
      ></a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import isFlight from "@/mixins/isFlight";

export default {
  name: "Slider",
  mixins: [isFlight],
  data: () => {
    return {
      step: 0,
      carouselPositions: [],
      halfContainer: 0,
      interval: false
    };
  },
  computed: {
    indexBannerComp() {
      return this.indexBanner.banners;
    },
    ...mapState("slider", {
      indexBanner: state => state.index,
      indexLoading: state => state.indexLoading
    }),
    ...mapState({
      width: state => state.width,
      height: state => state.height
    }),
    getSizeImage() {
      if (this.width > 1439) {
        this.init();
        return 7;
      } else if (this.width > 1279) {
        this.init();
        return 6;
      } else if (this.width > 1023) {
        this.init();
        return 5;
      } else if (this.width > 767) {
        this.init();
        return 4;
      } else if (this.width > 413) {
        this.init();
        return 3;
      } else if (this.width > 374) {
        this.init();
        return 2;
      } else {
        this.init();
        return 1;
      }
    }
  },
  watch: {
    indexLoading: {
      handler() {
        if (this.indexLoading === "empty") {
          this.actionIndex();
        }
      },
      immediate: true
    }
  },
  created() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(() => {
      this.nextStep();
    }, 10000);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    ...mapActions("slider", {
      actionIndex: "index"
    }),
    init() {
      this.$nextTick(() => {
        this.carouselPositions = [];
        document.querySelectorAll(".slider-container picture").forEach(div => {
          this.carouselPositions.push([
            div.offsetLeft,
            div.offsetLeft + div.offsetWidth
          ]); // add to array the positions information
        });

        this.halfContainer =
          document.querySelector(".slider-container").offsetWidth / 2;
      });
    },
    setStep(step) {
      try {
        this.step = step;
        clearInterval(this.interval);
        this.interval = setInterval(() => {
          this.nextStep();
        }, 10000);
        let slider = document.querySelector(".slider-container");
        if (slider) {
          slider.scrollTo({
            left: this.carouselPositions[this.step][0],
            behavior: "smooth"
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    prevStep() {
      if (this.step === 0) {
        this.step = this.carouselPositions.length - 1;
      } else {
        this.step--;
      }
      let slider = document.querySelector(".slider-container");
      if (slider) {
        slider.scrollTo({
          left: this.carouselPositions[this.step][0],
          behavior: "smooth"
        });
      }
    },
    nextStep() {
      try {
        if (this.carouselPositions.length - 1 === this.step) {
          this.step = 0;
        } else {
          this.step++;
        }
        let slider = document.querySelector(".slider-container");
        if (slider) {
          slider.scrollTo({
            left: this.carouselPositions[this.step][0],
            behavior: "smooth"
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    scrollSlider() {
      clearInterval(this.interval);
      document
        .querySelectorAll(".slider-container  picture")
        .forEach((div, index) => {
          let rect = div.getBoundingClientRect();
          if (rect.left === 0) {
            this.step = index;
          }
        });
      this.interval = setInterval(() => {
        this.nextStep();
      }, 10000);
      // console.log(
      //   event.srcElement.offsetWidth,
      //   event.target,
      //   this.halfContainer
      // );
    }
  }
};
</script>

<style scoped></style>
